<template>
  <div class="wrapper">
    <model-list
      :list="modelList"
      @checkProd="handleCheckProd"
      @checkAttr="handleCheckAttr"
      @addProd="handleAddProd"
    ></model-list>

    <prod-list :list="prodList" :show="prodShow"></prod-list>
    <attr-list
      :show="attrShow"
      :necessityAttr="necessityAttr"
      :appendAttr="appendAttr"
      :customAttr="customAttr"
    ></attr-list>
    <edit-prod :show="show" :type="type" :modelId="modelId" />
  </div>
</template>

<script>
import ModelList from "./components/ModelList";
import EditProd from "@/views/home/components/produce/components/EditProd";
import ProdList from "./components/ProdList";
import AttrList from "./components/AttrList";
import { mapState } from "vuex";
export default {
  name: "ProdModel",
  components: { ModelList, EditProd, ProdList, AttrList },
  props: {},
  data() {
    return {
      show: false,
      type: "add",
      modelId: null,
      modulesList: [],
      prodList: [],
      necessityAttr: [],
      appendAttr: [],
      customAttr: [],
      prodShow: false,
      attrShow: false,
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState(["modelList"]),
  },
  created() {
    this.getGoodsModels();
  },
  methods: {
    //获取模型列表
    async getGoodsModels() {
      await this.$store.dispatch("getGoodsModels");
    },
    //获取已构建产品列表
    async handleCheckProd(id) {
      this.modelId = id;
      this.prodList = await this.$store.dispatch("getModelFindProd", {
        model_id: this.modelId,
      });
      this.prodShow = !this.prodShow;
    },
    //获取模型可配置属性列表
    async handleCheckAttr(id) {
      const res = await this.$store.dispatch("getModelAttr", {
        model_id: id,
      });

      this.necessityAttr = res.attrs;
      this.appendAttr = res.extraAttrs;
      this.customAttr = Object.values(res.sideAttrs);
      this.customAttr = JSON.parse(
        JSON.stringify(this.customAttr).replace(/attributes/g, "children")
      );
      this.attrShow = !this.attrShow;
    },
    //新增产品
    handleAddProd(id) {
      this.modelId = id;
      this.show = !this.show;
      this.type = "add";
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  .el-row {
    display: flex;
    align-items: center;
    .el-dropdown {
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 100px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
  .el-table {
    margin-top: 20px;
    .green {
      color: #67c23a;
    }
  }
  .el-input-number--mini {
    width: 90px;
    /deep/.el-input__inner {
      height: 36px;
    }
    /deep/.el-input-number__increase {
      line-height: 18px !important;
    }
    /deep/.el-input-number__decrease {
      line-height: 18px !important;
    }
  }
  .mainAttr {
    .el-select {
      width: 100%;
    }
  }
  /deep/.el-form-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 34px;
  }
}
</style>
