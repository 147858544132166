<template>
  <table-container :list="list">
    <el-table-column type="index" width="50" sortable> </el-table-column>
    <el-table-column prop="name" label="模型名称"> </el-table-column>
    <el-table-column prop="name" label="已构建产品">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleCheckPro(scope.row.id)"
          >查看</el-button
        >
      </template>
    </el-table-column>
    <el-table-column prop="name" label="可配置属性">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleCheckAttr(scope.row.id)"
          >查看</el-button
        >
      </template>
    </el-table-column>
    <!-- <el-table-column label="操作" width="150">
      <template slot-scope="scope">
        <el-button
          size="mini"
          class="green"
          icon="el-icon-plus"
          type="text"
          @click="handleAddProd(scope.row.id)"
          >新增产品</el-button
        >
      </template>
    </el-table-column> -->
  </table-container>
</template>
<script>
export default {
  name: "ModelList",
  props: {
    list: Array,
  },
  methods: {
    //新增产品
    handleAddProd(id) {
      this.$emit("addProd", id);
    },
    //查看已构建产品
    handleCheckPro(id) {
      this.$emit("checkProd", id);
    },
    //查看模型属性
    handleCheckAttr(id) {
      this.$emit("checkAttr", id);
    },
  },
};
</script>
<style lang="less" scoped>
</style>