<template>
  <ky-dialog :title="title" :show="show" :hide="hide" width="50%">
    <table-container slot="dialog-content" :list="list" height="450">
      <el-table-column type="index" width="50" sortable label="序号">
      </el-table-column>
      <el-table-column property="model_name" label="模型"> </el-table-column>
      <el-table-column property="title" label="产品名称"></el-table-column>
      <el-table-column property="market_price" label="市场价"></el-table-column>
    </table-container>
  </ky-dialog>
</template>
<script>
export default {
  name: "ProdList",
  props: {
    show: Boolean,
    list: Array,
  },
  data() {
    return {
      title: "已构建产品列表",
      hide: false,
    };
  },
};
</script>
<style lang="less" scoped>
</style>