<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <div slot="dialog-content">
      <table-container :list="necessityAttr">
        <el-table-column label="必要属性">
          <el-table-column property="id" label="ID"> </el-table-column>
          <el-table-column property="name" label="属性"> </el-table-column>
        </el-table-column>
      </table-container>
      <table-container :list="appendAttr">
        <el-table-column label="附加属性">
          <el-table-column property="id" label="ID"> </el-table-column>
          <el-table-column property="name" label="属性"> </el-table-column>
        </el-table-column>
      </table-container>
      <table-container
        :list="customAttr"
        rowKey="id"
        :treeProps="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column label="定制属性">
          <el-table-column property="id" label="ID"> </el-table-column>
          <el-table-column property="name" label="属性"> </el-table-column>
        </el-table-column>
      </table-container>
    </div>
  </ky-dialog>
</template>
<script>
export default {
  name: "AttrList",
  props: {
    show: Boolean,
    necessityAttr: Array,
    appendAttr: Array,
    customAttr: Array,
  },
  data() {
    return {
      title: "",
      hide: false,
    };
  },
};
</script>
<style lang="less" scoped>
</style>